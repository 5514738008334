import { CreditNoteHeaderSearchCriteria } from "domain/entity/CreditNote/CreditNoteHeaderSearchCriteria";
import { CreditNoteConstant } from "presentation/constant/CreditNote/CreditNoteConstant";
import { useCreditNoteHeaderVM } from "presentation/hook/CreditNote/useCreditNoteHeaderVM";
import { useCreditNoteHeaderMaintenanceTracked } from "presentation/store/CreditNote/CreditNoteHeaderMaintenanceProvider";
import { useCallback, useState } from "react";
import { HPHButton, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarActionCross, SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import CreditNoteHeaderSearchForm from "./CreditNoteHeaderSearchForm";

const CreditNoteHeaderSearchPanel = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const CREDIT_NOTE_CONSTANT = CreditNoteConstant.Search;
    const [ creditNoteHeaderState ] = useCreditNoteHeaderMaintenanceTracked();
    const creditNoteHeaderVM = useCreditNoteHeaderVM();

    const resetButtonClicked = useCallback(() => {
        creditNoteHeaderVM.onSearchCriteriaResetClick();
    }, [creditNoteHeaderVM]);
    
    const searchButtonClicked = useCallback((criteria:CreditNoteHeaderSearchCriteria) => {
        setIsLoading(true);
        creditNoteHeaderVM.getCreditNoteHeaders(criteria).then((data) => {
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        })
        
    }, [creditNoteHeaderVM])

    return (
        <div className='side-form-content-left-wrapper' style={{padding:"20px 20px 20px 0px"}}>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{CREDIT_NOTE_CONSTANT.SEARCH_CRITERIA}</SidebarTitle>
                        <SidebarActionCross>
                            <IconButton
                                fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={creditNoteHeaderVM.onSearchClick} />
                        </SidebarActionCross>
                    </Sidebarheader>                
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" /> }

            <div className={'add-edit-form'} style={{ maxHeight:'75vh' , height:'75vh', overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        
                        {/* Stack Form Start */}
                        <CreditNoteHeaderSearchForm/>
                    </div>                  
                </div>
            </div>
            <SidebarActionBar>
                <HPHButton label={'Reset'} size={'Small'} theme={'Secondary'} onClick={resetButtonClicked} />
                <HPHButton label={'Search'} size={'Small'} theme={'Primary'} onClick={() => searchButtonClicked(creditNoteHeaderState.searchCriteria)} />
            </SidebarActionBar>
        </div>
    );
}

export default CreditNoteHeaderSearchPanel;